import React from 'react';

const contact = () => {
  return (
    <div id="contact">
        <h3>Join Us</h3>
        <div className='contact-input'>
          <a href="https://t.me/+a0T3S_iCdYJiOWI8"><i class="bi bi-telegram"></i></a>
          <a href="https://x.com/scroll_wolves"><i class="bi bi-twitter"></i></a>
        </div>
        
    </div>
  )
}

export default contact;