import React from 'react';

const Roadmap = () => {
  return (
  <section id="roadmap">
    <h2>Roadmap</h2>
    <ul>
        <li><strong>TBA 2024:</strong> Launch Wolf, Initial Marketing Campaign, Community Building</li>
        <li><strong>TBA 2024:</strong> Exchange Listings, First Token Burn, Meme Contest</li>
        <li><strong>TBA 2024:</strong> Partnership Announcements, More Exchange Listings</li>
        <li><strong>TBA 2025:</strong> Wolf Merchandise, Further Development of Ecosystem</li>
    </ul>
</section>
  )
}

export default Roadmap;
