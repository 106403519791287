import React from "react";
import ReactDOM from "react-dom";

import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import './index.css';

ReactDOM.render(
    <>
        <App />
    </>,
    document.getElementById("root")
)